<template>
  <div>
    <div class="row">
      <loading-notification :show="loading" />
      <retry-notification
        :show="error"
        @retry="updateData()"
      />
    </div>

    <div class="row">
      <div class="flex xs12 sm4">
        <va-card :title="$t('teams.view')">
          <actions
            slot="actions"
            crud-links="teams"
            :actions="actions"
            :action-data="{id: $route.params.id}"
          />
          <text-list :title="$t('tables.headings.code')">
            {{ team.code }}
          </text-list>
          <text-list :title="$t('tables.headings.status')">
            {{ getStatusLabel(team.status)}}
          </text-list>
          <text-list
            v-if="team.leader"
            :condition="currentUser.can('Users', 'view')"
            :title="$t('tables.headings.leader')"
            :label="team.leader.name"
            :to="{ name: 'usersView', params: {id: team.leader.id}}"
          />
          <text-list
            v-if="team.leader"
            :title="$t('tables.headings.team_leader_email')"
          >
            {{ team.leader.email }}
          </text-list>
          <text-list
            v-if="!team.leader && team.email"
            :title="$t('tables.headings.email')"
          >
            {{ team.email }}
          </text-list>
          <template v-if="team.district">
            <text-list
              :condition="currentUser.can('Districts', 'view')"
              :title="$t('tables.headings.district')"
              :label="$t(team.district.name)"
              :to="{ name: 'districtsView', params: {id: team.district.id}}"
            />
            <template v-if="team.district.country">
              <text-list
                :condition="currentUser.can('Countries', 'view')"
                :title="$t('tables.headings.country')"
                :label="$t(team.district.country.name)"
                :to="{ name: 'countriesView', params: {id: team.district.country.id}}"
              />
              <text-list
                v-if="team.district.country.region"
                :condition="currentUser.can('Regions', 'view')"
                :title="$t('tables.headings.region')"
                :label="$t(team.district.country.region.name)"
                :to="{ name: 'regionsView', params: {id: team.district.country.region.id}}"
              />
              <text-list
                v-if="team.responsible"
                :condition="currentUser.can('Users', 'view')"
                :title="$t('tables.headings.responsible')"
                :label="$t(team.responsible.name)"
                :to="{ name: 'usersView', params: {id: team.responsible.id}}"
              />
              <text-list
                v-if="team.date_responsible"
                :title="$t('tables.headings.date_responsible')"
                :label="$t(team.date_responsible)"
              />
            </template>
          </template>
          <text-list
            v-show="team.updated_at"
            :title="$t('tables.headings.last_modified')"
          >
            {{ team.updated_at | date }}
          </text-list>
        </va-card>
      </div>
      <div class="flex xs12 sm8">
        <va-card :title="$t('teams.tabs.title')">
          <tabs-container :tabs="tabs">
            <template v-slot:members>
              <members-table
                :team="team"
                :loading="loading"
              />
            </template>
            <template v-slot:history>
              <team-history
                :team-id="team.id"
                :loading="loading"
              />
            </template>
            <template
              v-slot:plans
              v-if="currentUser.can('TeamsYearPlans', 'index')"
            >
              <plan-history
                :team-id="team.id"
                :loading="loading"
              />
            </template>
          </tabs-container>
        </va-card>
      </div>
      <div class="flex xs12 sm12">
        <va-card :title="$t('teams.tabs.title')">
          <div class="row">
            <div class="flex xs12">
              <h6>{{$t('teams.inputs.equipementView')}} </h6>
              <vue-select-image ref="userImageSelect"
                root-class="equipement-style"
                :data-images="equipements"
                :is-multiple="true"
                :use-label="true"
                :selected-images="selectedEquipement"
                :custom = "active"
              ></vue-select-image>
            </div>
          </div>
          <div class="row">
            <div class="flex xs12">
              <va-checkbox
                :label="$t('teams.inputs.equipementMalFunction')"
                :disabled="true"
                v-model="equipment_isnotworking"
              />
            </div>
          </div>
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const MembersTable = () => import(/* webpackPrefetch: true */ './Members')
const TeamHistory = () => import(/* webpackPrefetch: true */ './History')
const PlanHistory = () => import(/* webpackPrefetch: true */ './Planning/Index')
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const TextList = () => import(/* webpackPrefetch: true */ '@/components/extras/Text/TextList')
const TabsContainer = () => import(/* webpackPrefetch: true */ '@/components/extras/TabsContainer')
const VueSelectImage = () => import(/* webpackPrefetch: true */ 'vue-select-image')

export default {
  name: 'teams-view',
  components: {
    MembersTable,
    TeamHistory,
    PlanHistory,
    Actions,
    TextList,
    TabsContainer,
    VueSelectImage,
  },
  data () {
    return {
      error: false,
      loading: false,
      team: {},
      actions: ['index', 'new', 'edit', 'delete'],
      selectedEquipement: [],
      equipment_isnotworking: false,
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    tabs () {
      const t = [
        { title: this.$t('teams.tabs.members.title'), name: 'members' },
        { title: this.$t('teams.tabs.history.title'), name: 'history' },
      ]

      if (this.currentUser.can('TeamsYearPlans', 'index')) {
        t.push({ title: this.$t('teams.tabs.planning.title'), name: 'plans' })
      }

      return t
    },
    equipements () {
      const e = [
        {
          id: '1',
          src: '/img/equipements/full_equipement.jpg',
          alt: this.$t('teams.equipements.backpack'),
        }, {
          id: '2',
          src: '/img/equipements/semi_equipement.jpg',
          alt: this.$t('teams.equipements.solar'),
        }, {
          id: '3',
          src: '/img/equipements/large_equipement.jpg',
          alt: this.$t('teams.equipements.large'),
        }, {
          id: '5',
          src: '/img/equipements/tablet.jpeg',
          alt: this.$t('teams.equipements.handheld'),
        }, {
          id: '4',
          src: '/img/equipements/no_equipement.png',
          alt: this.$t('teams.equipements.none'),
        },
      ]
      return e
    },
  },
  created () {
    this.updateData()
  },
  watch: {
    team (val) {
      const a = val.teams_year_plans[0].equipement.split(',')
      a.forEach(element => {
        const p = this.equipements.find(x => x.id === element)
        if (p) {
          this.selectedEquipement.push(p)
        }
      })
      const component = this.$refs.userImageSelect
      component.setInitialSelection()

      this.equipment_isnotworking = val.teams_year_plans[0].equipment_isnotworking
    },
  },
  methods: {
    getStatusLabel (status) {
      let label = 'teams.status.renewed'
      switch (status) {
        case 0: label = 'teams.status.new'; break
        case -1: label = 'teams.status.removed'; break
      }
      return this.$t(label)
    },
    // FIXME
    routeBuilder (id) {
      return `teams/${id}`
    },
    async updateData () {
      this.loading = true
      this.error = false

      let u = false
      const teamId = this.$route.params.id
      try {
        u = await this.$http.get(this.routeBuilder(teamId))
      } catch (err) {
        // console.log('Error fetching team data', err)
        this.error = true
        this.loading = false
        return
      }

      this.loading = false
      this.team = u.data.data
    },
    selectEquipement (data) {
      this.selectedEquipement = data
    },
  },
}
</script>
<style lang="scss">
.equipement-style {
  display: flex;
  justify-content: center;

  &__wrapper {
    overflow: auto;
    list-style-image: none;
    list-style-position: outside;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    margin: 0 12px 12px 0;
    float: left;

    @media only screen and (min-width: 1200px) {
      margin-left: 30px;
    }
  }

  &__thumbnail {
    padding: 6px;
    border: 1px solid #dddddd;
    display: block;
    // padding: 4px;
    line-height: 20px;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;

    &--selected {
      background: #0088cc;
      color: white;

      .custom-style__img {
        zoom: 1.1;
      }
    }
  }

  &__img {
    -webkit-user-drag: none;
    display: block;
    width: 10vw;
    min-width: 150px;
    border-radius: 5px;
    margin-right: auto;
    margin-left: auto;
    height: 120px;
    cursor: pointer;
  }

  &__lbl {
    display: block;
    font-weight: bold;
    text-align: center;
  }
}
</style>
